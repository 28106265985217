import React, { useState, useEffect, useRef } from "react";
import { Button } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useStyles } from "./styles";
import PropertyMenu from "../PropertyMenu";
import apiClient from "../../auth/apiClient";
import EntityService from "../../services/EntityService";
import { DEFAULT_PROPERTY_ID, DEFAULT_SCOPE_AWARE_IDS } from "../../constants";
import { useHistory } from "react-router-dom";
import { changeFacilities } from "../../state/slices/entities";
import { changeNewFacilities } from "../../state/slices/CoreEntity";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useDispatch } from "react-redux";
import useCurrentFacility from "../../hooks/useCurrentFacility";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import useCurrentUser from "../../hooks/useCurrentUser";
import _ from "lodash";
import {useCoreEntityContext} from "../../hooks/useCoreEntitySlice";

const entityService = new EntityService(apiClient);

const PropertyButton = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smallMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [lastSearchTerm, setLastSearchTerm] = useState();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const { facilityID, facilityName } = useCurrentFacility();
  const enqueueSnackbar = useEnqueueSnackbar();
  const facilityListRef = useRef();
  const useCoreEntitySlice = useCoreEntityContext();
  const currentUser = useCurrentUser();

  useEffect(() => {
    getProperties().then(props => setProperties(props));
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem(DEFAULT_PROPERTY_ID, facilityID);
  }, [facilityID]);

  const onMenuButtonClick = event => {
    getProperties(lastSearchTerm)
      .then(props => setProperties(props))
      .catch(err => console.error(err));
    setIsDialogOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleSelectedProperty = async property => {
    if (!property.length) return;
    window.sessionStorage.removeItem(DEFAULT_SCOPE_AWARE_IDS);
    handleFacilityClose();
    history.push(`/home`);
    const facilityID = property[0].facilityID;
    await dispatch(
        useCoreEntitySlice ?
            changeNewFacilities({ facilityID, userID: currentUser.UserID })
          :
            changeFacilities({ facilityID, userID: currentUser.UserID })
    );
  };

  const handleFacilityClose = () => {
    setIsDialogOpen(false);
  };

  const getPropertyText = () => {
    if (smallMatch === true) {
      return "";
    }
    return facilityName ?? "Facilities";
  };

  const setProperties = properties => {
    setFilteredProperties(_.orderBy(properties,"[name]","asc"));
  };

  const onSearchChange = async searchTerm => {
    setLastSearchTerm(searchTerm);
    const props = await getProperties(searchTerm);
    setProperties(props);
  };

  const getProperties = async searchTerm => {
    let properties = [];
    try {
      const result = await entityService.getPropertiesByScopes(searchTerm);
      properties = result.data;
      if (result?.status !== 200) {
        enqueueSnackbar("We ran into a problem while getting properties", {
          variant: "error",
          tag: "fetchPropertiesError"
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("We ran into a problem while getting properties", {
        variant: "error",
        tag: "fetchPropertiesError"
      });
    }

    return properties;
  };

  return (
    <>
      <Button
        data-id="facility-modal-btn"
        data-facility={facilityName ?? "Facilities"}
        className={classes.button}
        startIcon={<EmojiTransportationIcon />}
        endIcon={smallMatch === false ? <ArrowDropDownIcon /> : null}
        onClick={onMenuButtonClick}
      >
        {getPropertyText()}
      </Button>
      <PropertyMenu
        isOpen={isDialogOpen}
        onClose={handleFacilityClose}
        onPropertiesSelected={property => handleSelectedProperty(property)}
        properties={filteredProperties}
        onSearchChange={onSearchChange}
        currentAnchorEl={anchorEl}
        listRef={facilityListRef}
      />
    </>
  );
};

export default PropertyButton;
